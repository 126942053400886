/* Page layout
-------------------------------------------------- */
body.team {
  margin-top: $header-height + 80px;

  @media screen and (max-width: $screen-width-xs) {
    margin-top: $header-height + 14px;

    .page-content {
      padding-bottom: 20px;
    }
  }

  .page-footer {
    margin-top: 20px;
  }
}

/* Page typography
-------------------------------------------------- */
body.team {
  .subhead {
    text-align: center;

    @media screen and (max-width: $screen-width-xs) {
      width: 90%;
    }
  }

  h2 {
    margin: 0 0 20px;
    font-size: 50px;
    color: #245f74;

    @media screen and (max-width: $screen-width-xs) {
      font-size: 30px;
      margin-bottom: 12px;
    }
  }

  p {
    font-size: 18px;
    color: #999b9f;
    margin-bottom: 0;

    @media screen and (max-width: $screen-width-xs) {
      font-size: 15px;
    }
  }
}

.members {
  margin-top: 74px;
  padding-left: 5px;
  text-align: center;
  max-width: 1040px;

  @media screen and (max-width: 1065px) {
    width: 96%;
    margin: 60px auto 0;
  }

  @media screen and (max-width: $screen-width-xs) {
    margin-top: 40px;
  }

  .caption {
    text-align: left;
    padding: 24px 20px;

    h4 {
      font-size: 26px;
      color: #42515f;
      margin-top: 0;
      margin-bottom: 16px;
    }

    p {
      font-size: 14px;
      color: #636e79;
    }

    @media screen and (max-width: $screen-width-xs) {
      display: inline-block;
      width: 53%;
      padding: 17px 15px;
    }
  }
}

.member {
  border: 1px solid rgba(0, 0, 0, 0.1);
  max-width: 252px;
  width: 18%;
  margin: 0 4px 28px;
  background-color: #fff;
  display: inline-block;
  vertical-align: top;

  @media screen and (max-width: 1065px) {
    width: 23%;
  }

  .profile-hover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #000;
      opacity: 0.6;
    }

    .social {
      height: 60px;
      position: absolute;
      top: 50%;
      margin-top: -30px;
      width: 100%;

      & > a {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
  }

  .profile {
    width: 100%;
    position: relative;

    & > img {
      width: 100%;
    }

    .member-twitter {
      @include img('/img/team/btn_icon_twitter', 60px, 60px);
    }
    .member-github {
      @include img('/img/team/btn_icon_github', 60px, 60px);
    }
    .member-facebook {
      @include img('/img/team/btn_icon_facebook', 60px, 60px);
    }
    .member-behance {
      @include img('/img/team/btn_icon_behance', 60px, 60px);
    }
    .member-mail {
      @include img('/img/team/btn_icon_mail', 60px, 60px);
    }
  }

  &:hover {
    .profile-hover {
      display: block;
    }
  }

  @media screen and (max-width: $screen-width-xs) {
    display: block;
    width: 90%;
    max-width: $screen-width-xs;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    .profile {
      width: 45%;
      display: inline-block;
      vertical-align: top;
    }
  }
}

.about-team {
  display: block;
  vertical-align: top;

  a.card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    max-height: 512px;
    width: 48%;
    color: #fff;
    margin: 0 4px;
    display: inline-block;
    text-align: left;
    padding: 24px 40px 24px;
    position: relative;
    text-decoration: none;
    @media screen and (max-width: $screen-width-xs) {
      width: 100%;
      margin-bottom: 20px;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 15px;
      margin-top: -11px;
      @include img('/img/team/img_team_arrow', 14px, 22px);
    }

    h4 {
      font-size: 20px;
      margin-top: 6px;
    }

    p {
      font-size: 14px;
      color: #fff;
      opacity: 0.5;
    }
  }

  .team-goal {
    background-color: #2db7f1;
    &:hover {
      background-color: #28a4d8;
    }
  }
  .team-helpus {
    background-color: #516170;
    &:hover {
      background-color: #495765;
    }
  }

  @media screen and (max-width: $screen-width-xs) {
    width: 90%;
    max-width: $screen-width-xs;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    h4 {
      margin-bottom: 8px;
    }
  }
}
